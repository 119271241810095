var headerHeight = document.querySelector("header").clientHeight;
document.querySelector(".contentwrapper").style.paddingTop = headerHeight + "px";   
  
var footerHeight = document.querySelector("footer").offsetHeight;  
var viewportHeight = window.innerHeight;
document.querySelector(".contentwrapper").style.minHeight  = viewportHeight - footerHeight - 20 + "px"; 

window.onload = function () {
	scrollactions.init();
}

