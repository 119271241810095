// var ready = (callback) => {
// 	if (document.readyState != "loading") callback();
// 	else document.addEventListener("DOMContentLoaded", callback);
// }
// ready(() => {
// 	console.log("document DOM is ready")
// });

var headerHeight = document.querySelector("header").clientHeight;
document.querySelector(".contentwrapper").style.paddingTop = headerHeight + "px";   
  
var footerHeight = document.querySelector("footer").offsetHeight;  
var viewportHeight = window.innerHeight;
document.querySelector(".contentwrapper").style.minHeight  = viewportHeight - footerHeight - 20 + "px"; 

window.onload = function () {
	// console.log("window onload - it works")
	scrollactions.init();
	// collapse.init(); 
	// hovercollapse.init();
	// dropdown.init();
	// mobileFilter.init();
	// search.init();
	// toast.init(); 
	// youtubesetsource.init(); // moet na fixedheader ingeladen worden

}

