const scrollactions = {
	init: function () {
		
		var header = document.querySelector("header")
		// var navtopHeight = document.querySelector(".nav__top").clientHeight;

		function scrollToTop() {
			if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
				document.getElementById("backTop").className = "";
				header.classList.add("shrink")
				// header.style.marginTop = - navtopHeight + "px"; 
				// document.querySelector(".nav__main").style.marginTop = 0 + "px"; 
			} else {
				document.getElementById("backTop").className = "masked";
				header.classList.remove("shrink")
				// header.style.marginTop = 0; 
				// document.querySelector(".nav__main").style.marginTop = 1 + "rem"; 
			}
		}



		window.onscroll = function () { 
			scrollToTop() 
		};

		window.addEventListener("load", function() {
			if (window.matchMedia("(min-width: 500px)").matches) {
				// console.log("Screen width is at least 500px");
				// scrollactions.init();
				// scrollToTop() 
			} else {
				// console.log("Screen less than 500px");
				// scrollToTop() 
			}
		});
		
		window.addEventListener("resize", function() {
			if (window.matchMedia("(min-width: 500px)").matches) {
				// console.log("resized Screen width is at least 500px");
				// scrollactions.init();
			} else {
				// console.log("resized Screen less than 500px");
			}
		});


	}
}

// WANNEER FILTER UITGEKLAPT OP MOBILE > VERBERG BACKTOP WANT DIE STAAT BOVEN ALLES
/* $('#collapseShow').on('shown.bs.collapse', function () {
	$('.backTop-container').css('right','-80px')
 });
 
 $('#collapseShow').on('hidden.bs.collapse', function () {
	$('.backTop-container').css('right','0')
 }); */